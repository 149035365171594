import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import flatpickr from 'flatpickr';
import { French } from 'flatpickr/dist/l10n/fr.js';

dayjs.locale('fr');

flatpickr('.datepicker', {
    locale: French,
    allowInput: true,
    altInput: true,
    dateFormat: 'Y-m-d',
    altFormat: 'd/m/Y',
    altInputClass: '',
    locale: 'fr',
});
