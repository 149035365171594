import 'animate.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { alertClasses } from './Alerts';

window.Swal = Swal;
globalThis.Swal = Swal;

document.querySelectorAll('[data-alert]').forEach((element) => {
    const value = element.dataset['alert'];

    element.addEventListener('click', () => {
        Swal.fire({
            ...alertClasses,
            html: `La recherche est sauvegardée.`,
            showConfirmButton: false,
            showDenyButton: false,
            showCancelButton: false,

            toast: true,
            customClass: {
                popup: 'copy',
            },
            showClass: {
                popup: 'animate__animated animate__fadeInRightBig',
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutRightBig',
            },
            timer: 3500,
        });
    });
});
