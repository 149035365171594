export default function Menu(selector) {
    const links = document.querySelectorAll(`.link`);
    const menusActive = (document.getElementById('menuActive') ? document.getElementById('menuActive').value : '').split('.');

    links.forEach((link) => {
        menusActive.forEach((menuActive) => {
            if (menuActive === link.dataset.type) {

                link.classList.add('active', 'open');

                const parent = link.parentElement;
                const dropdown = parent.querySelector('.dropdown__items');
                if (dropdown) {
                    dropdown.classList.toggle('open');
                }
            }
        });
    });
}