import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

document.querySelectorAll('[data-tooltip]').forEach((element) => {
    const value = element.dataset['tooltip'];
    let content = element.dataset['tooltipContent'];

    switch (value) {
        default:
            tippy(element, {
                placement: 'left',
                content: content,
                arrow: true,
            });
            break;
    }
});
