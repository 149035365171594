export default class Dropdown {
    constructor(selector, options = {}) {
        this.selector = selector;
        this.options = Object.assign({}, options);
        this.init();
    }
    init() {
        for (const element of this.selector.children) {
            if (element.tagName == 'BUTTON') {
                element.addEventListener('click', () => {
                    this.toggle(element);
                    this.toggle(element.nextElementSibling);
                });
            }
        }

        // si je clique a l'exterieur de mon dropdown, je ferme le dropdown
        this.selector.addEventListener('click', (e) => {
            if (
                this.selector.children[0].nextElementSibling.classList.contains(
                    'open'
                )
            ) {
                this.addPadding('.dropdown__items--navbar');
            } else {
                this.removePadding('.dropdown__items--navbar');
            }

            if (this.selector.querySelector('.icons-chevron-right')) {
                this.selector
                    .querySelector('.icons-chevron-right')
                    .classList.toggle('rotate');
            }
        });

        this.handleClickOutside(['.dropdown__button--navbar'], () => {
            const button = this.selector.querySelector('button');

            if (
                button.classList.contains('dropdown__button--navbar') &&
                button.classList.contains('open')
            ) {
                const items = this.selector.querySelector(
                    '.dropdown__items.open'
                );
                if (items) {
                    items.classList.remove('open');
                    button.classList.remove('open');
                }
                this.removePadding('.dropdown__items--navbar');
            }
        });

        this.isScreenPosition();
    }

    toggle = (ele) => {
        ele?.classList.toggle('open');
    };

    addPadding = (ele) => {
        const element = this.selector.querySelector(ele);
        if (element) {
            element.style.paddingBottom = '1.5em';
        }
    };

    removePadding = (ele) => {
        const element = this.selector.querySelector(ele);
        if (element) {
            element.style.paddingBottom = '0';
        }
    };

    isScreenPosition = () => {
        const button = this.selector.children[0];
        const buttonRect = button.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        const rightPosButton = windowWidth - buttonRect.right;

        const items = this.selector.children[0].nextElementSibling;
        const itemsRect = items.getBoundingClientRect();
        const rightPosItems = windowWidth - itemsRect.right;

        if (rightPosItems < 0) {
            const position = rightPosButton - 10;
            this.selector.children[0].nextElementSibling.style.left = `initial`;
            this.selector.children[0].nextElementSibling.style.right = `-${position}px`;
        }
    };

    handleClickOutside(selectors, callback) {
        document.addEventListener('click', function (event) {
            // Parcours tous les sélecteurs donnés
            let clickedInside = selectors.some((selector) => {
                const elements = document.querySelectorAll(selector);
                return Array.from(elements).some((element) =>
                    element.contains(event.target)
                );
            });

            // Si le clic est à l'extérieur des éléments spécifiés
            if (!clickedInside) {
                callback();
            }
        });
    }
}
